import React from "react"
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import PageTitle from "../components/page-title"

export default () => (
  <Layout title="wood">
    <p>Sometimes I build shit out of wood. I used hand tools. It's Tim Ewald's fault.</p>
  </Layout>
)

